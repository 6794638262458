import React, { Component } from 'react';

import './Navigation.css';

export default class Navigation extends Component{

    strings = [
        { name: 'all', label: 'Все'},
        { name: 'landing', label: 'Landing page'},
        { name: 'multipage', label: 'Многостраничный сайт'},
        { name: 'shop', label: 'Интернет магазин'},
    ];


    render() {
        const { filter, onFilterChange } = this.props;
        const strings = this.strings.map(({name,label}) => {
            const isActive = filter === name;
            const clazz = isActive ? 'active' : 'none'; 
            return(
            <li className={`portfolio-li ${clazz}`}
                key={name}
                onClick={() => onFilterChange(name)}>
                {label}
            </li>
            );
        });
        return(
            <div className="row">
                <div className="col-lg-12">
                    <ul className="portfolio-ul">
                        {strings}
                    </ul>
                </div>
            </div>
        );
    };
}