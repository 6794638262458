import React,{Component} from 'react';

import RewiewsItems from './rewiewsitem/';

import cosmoland from './img/kosmoland.jpg';
import credit from './img/credit.jpg';
import checks from './img/checks.jpg';
import podimim from  './img/danya.jpg'


export default class Rewiews extends Component{

    state = {
        rewiews: [
            { id: 3, img: checks , name: "Яков Яковлев(Чеки 24)", label: 'Хотел выразить Никите благодарность 🤝 за качественную профессионально выполненную работу. Если была бы возможность ставить оценки в виде звёзд, то однозначно это было бы 5 из 5👌! Безусловно буду в дальнейшем обращаться к нему для создания сайтов и рекомендовать тем, кто обратится с подобными задачами. Работа супер 👍, кто видел отзывались только положительно.'},
            { id: 1, img: cosmoland, name: "Евгений(KosmoLand)", label: 'Хочу выразить огромную благодарность Никите Горячеву за создание сайта! Работа была выполнена очень качественно и в срок. Сайт получился оригинальный и отличный от других. Никита умеет слышать то, что хочет заказчик, предлагает свои идеи и интересные решения. Отличный дизайн сайта! Очень информативный, но не перегруженный. Сайт запущен в работу несколько дней, а мы уже почувствовали отдачу от него! Всем рекомендую! Огромное спасибо!'},
            { id: 2, img: credit, name: "Наталья(Кредит для вас)", label: 'Обратилась за помощью в создании сайта, Никита сделал замечательный сайт, быстро и качественно, спасибо! Буду ещё обращаться и советовать друзьям'},
            { id: 4, img: podimim, name: "Даниил(#Podimim)", label: 'Все работает! Неделю назад уже запустили рекламу и клиенты идут. Внешне именно такой какой в голове представлял. По ссылке открывается быстро, не нужно выжидать прогрузки. Рад сотрудничеству, определенно рекомендую всем.'}
        ]
    };

    render(){
        const {rewiews} = this.state;
        return(
            <div className="swiper-container" >
                <RewiewsItems rewiews={rewiews} />
                <div className="swiper-pagination"></div>
            </div>
        );
    }
}

