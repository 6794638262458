import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import App from './Component/App/';
import Rewiews from './Component/rewiews/';

const root = document.getElementById('root');
const rewiews = document.getElementById('rewiews');



 ReactDOM.render(<App/> , root);
 ReactDOM.render(<Rewiews/>, rewiews);