import React, { Component } from 'react';


import Header from './Header/';
import Portfolio from './Portfolio/';
import Navigation from './Navigation/';
import Plaster from '../../img/first.jpg';
import Cosmoland from '../../img/three.jpg';
import Vr from '../../img/four.jpg';
import Talent from '../../img/five.jpg';
import Graphika from '../../img/six.jpg';
import Pishi from '../../img/pishi.jpg';
import Credit from '../../img/kredit.jpg';
import Instrument from '../../img/instrument.jpg';
import Argus from '../../img/argus.jpg';
import Podimim from '../../img/podimim.jpg';
import Remont from '../../img/remont.jpg';
import Stiral from '../../img/stiral.jpg';
import Mahmud from '../../img/mahmud.jpg';



export default class App extends Component{

    state = {
        portfolio: [
            { key: 10, id: 10, name: 'multipage', label: '"Будет сделано"', paragraph: 'Ремонт бытовой техники', twicename: 'Интересный проект, было реализовано много того, что до этого не использовалось, такое как форма обратной связи со списком услуг, где заказчику на почту приходит название конкретной услуги. Была добавляна простая админка для дальнейшего изменения текста. Так же был подключен "Яндекс метрика" и настройка рекламы.', time: '12 дней', link: 'https://budetsdel.ru/', img: Stiral },
            { key: 11, id: 11, name: 'landing', label: '"#Podimim"', paragraph: 'Аренда кальянов', twicename: 'Красивый, интересный дизайн и очень быстрая реализация в кратчайшие сроки.', time: '1 день', link: 'https://podimim72.ru/', img: Podimim  },
            { key: 14, id: 14, name: 'landing', label: '"Mahmud"', paragraph: 'Масло черного тмина', twicename: 'Быстрый проект для получения быстрых продаж.', time: '1 день', link: 'https://goraychenok.github.io/mahmud/', img: Mahmud  },
            { key: 13, id: 13, name: 'multipage', label: '"Аргус"', paragraph: 'Обеспечение пожарной безопасности', twicename: 'Описание: Не законнченый проект, так как компания перестала выходить на связь. Результат проделанной работы можете посмотреть по ссылке', time: '-', link: 'https://goraychenok.github.io/argus_first/', img: Argus },
            { key: 12, id: 12, name: 'landing', label: '"Инструмент-ремонт"', paragraph: 'Ремонт электроинструментов', twicename: 'Отличный одностраничный сайт. Простой, без обратной связи, только с вызовом.', time: '1 день', link: 'https://instrument-remont72.ru/', img: Remont  },
            { key: 9, id: 9, name: 'multipage', label: '"Инструмент-прокат"', paragraph: 'Прокат строительного инструмента', twicename: 'Описание: Хороший многостраничный сайт для аренды строительных инструментов. Сроки были сжаты, заказчик отказался от дизайнера. Верстка была натянута на WP.', time: '3 дня', link: 'https://instrument-prokat72.ru/', img: Instrument },
            { key: 6, id: 6, name: 'landing', label: '"Полис Е-ОСАГО"', paragraph: 'Электронный полис ОСАГО', twicename: 'Описание: Отличный пример качественной компоновки сайта, идеально подобраны оттенки. На сайте есть не большое колличество анимации, именно поэтому по всем скоростным тестам он выдает высокие баллы.', time: '5 дней', link: 'https://goraychenok.github.io/e-osago/', img: Graphika },
            { key: 5, id: 5, name: 'landing', label: '"Чеки 24"', paragraph: 'Кассовые чеки и счёт фактуры под ваши цели', twicename: 'Описание: Дизайн был разработан специалистом, утвержден с заказчиком. После верстки на сайт поставили Пиксель и яндекс метрику.', time: '5 дней', link: 'https://checks24.ru/', img: Talent  },
            { key: 1, id: 1, name: 'landing', label: '"Гипсолит"', paragraph: 'Автоматизированная штукатурка стен', twicename: 'Дизайн делался мной по желанию заказчика в зелёных оттенках. Заказчик хотел, поместить все на одной странице: товар, отзывы, контакты и тд. И чтобы сайт не был слишком длинный. Добавили форму отправки, чтобы иметь обратную связь с клиентами. С задачей справился за три дня.', time: '3 дня', link: 'https://goraychenok.github.io/gibsolit/', img: Plaster },
            { key: 3, id: 3, name: 'landing', label: '"Космоленд"', paragraph: 'Развлекательная комната для детей', twicename: 'Заказчик пришёл без дизайна. Дан был лишь логотип и несколько фото. Исходя из финансов и удобства выбрали одностраничный сайт. Дизайн выполнен в космическом стиле в фиолетовых тонах. Сайт поставлен на Wordpress, чтобы заказчик мог без помощи программиста добавлять фото, услуги, отзывы.', time: '4 дня', link: 'http://xn--72-jlcxffifb2a9k.xn--p1ai/', img: Cosmoland  },
            { key: 4, id: 4, name: 'landing', label: '"VR-box"', paragraph: 'Очки виртуальной реальности', twicename: 'Описание: одностраничный сайт для очков виртуальной реальности выполнен по готовому дизайну. Пожеланием заказчика были множество анимаций и быстрая прогрузка сайта. Вышло 92 балла для компьютера', time: '3 дня', link: 'https://goraychenok.github.io/VirtualGlasses/', img: Vr  },
            { key: 7, id: 7, name: 'landing', label: '"Кредит для вас"', paragraph: 'Помощь погашения кредита', twicename: 'Описание: одностраничный сайт для очков виртуальной реальности выполнен по готовому дизайну. Пожеланием заказчика были множество анимаций и быстрая прогрузка сайта. Вышло 92 балла для компьютера', time: '3 дня', link: 'https://credit-dlya-vas72.ru/', img: Credit  },
            { key: 8, id: 8, name: 'shop', label: '"Пиши-Стирай"', paragraph: 'Канцелярский магазин', twicename: 'Интернет-магазин, настроено добавление товаров через WP. Настроена оплата и статистика через Woocomerce. Очень прост в использовании. Политика конфиденциальность соблюдена.', time: '5 дней', link: 'https://pishistiray.ru/', img: Pishi }
        ],
        filter: 'all' //landing, all, multipage, shop, app
      };

      onFilterChange = (filter) => {
        this.setState({filter});
      };

      filter(items, filter) {
        switch(filter) {
          case 'all' : 
            return items;
          case 'landing' :
            return items.filter((item) => item.name === filter );
          case 'shop' :
            return items.filter((item) => item.name === filter );
          case 'multipage' :
            return items.filter((item) => item.name === filter );
          default :
            return items;
        }
      };

    render(){

        const {portfolio, filter} = this.state;
        const visibleItems = this.filter(portfolio , filter);


        return(
            <div className="container">
                <Header />
                <Navigation 
                filter={filter}
                onFilterChange={this.onFilterChange}/>
                <Portfolio
                portfolios={visibleItems}  />
            </div>
        );
    };
};