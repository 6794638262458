import React, { Component } from 'react';

import './Header.css';

export default class Header extends Component{
    render() {
        return(
            <div className="row">
                <div className="col-lg-4">
                    <h2 className="title-name">Портфолио</h2>
                </div>
            </div>
        );
    };
}