import React from 'react';

import PortfolioItems from '../portfolioitems/index';

import './Portfolio.css';



const Portfolio = ({portfolios}) => {

    const elements = portfolios.map((item) => {
        const {key, ...itemProps} = item;

        return(
            <div className="col-lg-3 col-md-4 col-sm-6 col-6 portfolio-col"
                 key={key}
            >
                <PortfolioItems {...itemProps} />
            </div>
        );
        
    });

    return(
        <div className="row">
            {elements}
        </div>
    )
};


    export default Portfolio;



