import React, {Component} from 'react';

import Modal from '../Modal/index';
import './portfolioitems.css';




export default class PortfolioItems extends Component{
    

   
    

        state = {
            showModal: 0
        };
    
        getModal = value => {
            
            this.setState({
                showModal: value
            });
           
        };
    
        hideModal = id => {
            this.setState({
                showModal: 0
            });
        };
        render(){    
            const { label, img, paragraph, name, id, time, twicename, link} = this.props; 
                return(
                    
                     <div>
                         <div className="blocking-block"
                        onClick={() => this.getModal(id)}>
                        <div className="portfolio-block ">
                            <div className="portfolio-block-img">
                                <img src= { img } className="portfolio-img" />
                            </div> 
                            <h4 class="portfolio-title">
                            {label}
                            </h4>
                            <p className="portfolio-paragraph">{paragraph}</p> 
                        </div>
                        
                    </div>
                    <Modal 
                    show={this.state.showModal === id }
                    onHide={ () => this.hideModal() }
                    name= {name}
                    id = {id}
                    img = {img}
                    label = {label}
                    time = {time}
                    twicename = {twicename}
                    link = {link}
                 /> 
                     </div>
                
             );
                }  
}