import React,{Component} from 'react';



export default class Modal extends Component {

    render(){
        const {id} = this.props;
    return(
        <React.Fragment>
            {this.props.show && (
                <div className="gibsolit">
                <div className="gibsolit-dialog">
                    <div className="gibsolit-content">
                    <button className="gibsolit-close"
                    onClick={this.props.onHide}>
                        &times;
                    </button>
                    <img src={this.props.img} alt="" className="gibsolit-img"/>
                    <h4 className="gibsolit-header">
                        {this.props.label}
                    </h4>
                    <p className="gibsolit-header-text">Тип сайта: {this.props.name} </p>
                    <p className="gibsolit-header-text">Время выполнения: {this.props.time} </p>
                    <p className="gibsolit-header-text">{this.props.twicename}</p>
                    
                    <p className="gibsolit-header-text"><a href={this.props.link}>Ссылка на сайт</a></p>
                    </div>
                </div>
            </div>
            )}
        </React.Fragment>
    );
    };
   
};