import React from 'react';

const RewiewsItems = ({rewiews}) => {
    const elements = rewiews.map(({id,img,name,label}) => {

        return(
            <div className="swiper-slide" 
            key={id}>
                <div className="rewiews-block">
                    <img src={img} alt="" className="rewiews-img"/>
                    <h3 className="rewiews-title">{name}</h3>
        <p className="rewiews-paragraph">{label}</p>
                </div>
            </div>
        );
    });

    return(
        <div className="swiper-wrapper">
            {elements}
        </div>
    )
}
export default RewiewsItems;